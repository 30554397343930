import React from 'react'
import { Box } from '@mui/material'
import dayjs from 'dayjs'

const contact = {
    name: 'Gọi điện cho khách A',
    detail: 'Gọi điện cho khách A với nội dung',
    status: 'Đặt cọc',
    staff: 'Thu Hà'
}

const TaskItem = ({ style, ...props }) => {
    return (
        <div {...props} style={{ ...style, display: 'flex', background: 'white', borderRadius: 4, marginBottom: '16px', flexDirection: 'column' }}>
            <span style={{ fontSize: 12, fontWeight: '500' }}>Thông tin cơ bản</span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Tên nhiệm vụ: '}
                {contact && contact.name && <span style={{ color: 'blue' }}>{contact.full_name}</span>}</span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Thông tin chi tiết: '}
                <span style={{ color: 'blue' }}>{contact.detail}</span>
            </span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Thời gian bắt đầu: '}
                <span style={{ color: 'gray' }}>{dayjs().format('HH:mm:ss DD/MM/YYYY')}</span>
            </span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Thời gian kết thúc: '}
                <span style={{ color: 'gray' }}>{dayjs().format('HH:mm:ss DD/MM/YYYY')}</span>
            </span>

            <span style={{ fontSize: 12, fontWeight: '500', marginTop: '16px' }}>Nhân sự phụ trách</span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Nhân viên: '}
                <span style={{ color: 'blue' }}>{contact.staff}</span>
            </span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Phòng ban '}</span>
        </div>
    )
}

export default TaskItem