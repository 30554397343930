import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const PaymentItem = ({ row }) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/crm/group/${row}`)
    }

    return (
        <StyledTableRow
            key={row}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={openDetail}
            style={{ cursor: 'pointer' }}
        >
            <TableCell style={{ minWidth: '100px' }}>{dayjs().format('HH:mm:ss DD/MM/YYYY')}</TableCell>
            <TableCell align="right" >Nguyễn Quỳnh Anh</TableCell>
            <TableCell align="right" >Đơn hàng</TableCell>
            <TableCell align="right" >Mã báo giá</TableCell>
            <TableCell align="right" >Trạng thái</TableCell>
            <TableCell align="right" >Tổng tiền trước thuế</TableCell>
            <TableCell align="right" >Tổng chiết khấu</TableCell>
            <TableCell align="right" >Tổng VAT</TableCell>
            <TableCell align="right" >Tổng cộng</TableCell>
            <TableCell align="right" >Mã sản phẩm</TableCell>
            <TableCell align="right" >Tên sản phẩm</TableCell>
            <TableCell align="right" >Số lượng</TableCell>
            <TableCell align="right" >Giá</TableCell>
            <TableCell align="right" >Chiết khấu %</TableCell>
            <TableCell align="right" >Chiếu khấu đ</TableCell>
            <TableCell align="right" >Thành tiền</TableCell>
            <TableCell align="right" >Nhân viên</TableCell>
            <TableCell align="right" >ID</TableCell>
            <TableCell align="right" >Auto ID</TableCell>
            <TableCell align="right" >{dayjs().format('HH:mm:ss DD/MM/YYYY')}</TableCell>
            <TableCell align="right" >Số điện thoại</TableCell>
        </StyledTableRow>
    )
}

export default PaymentItem