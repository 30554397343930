import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';
import { CheckBox } from '@mui/icons-material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StaffAbilityItem = ({ row }) => {
    const history = useHistory()

    return (
        <StyledTableRow
            key={row.title}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {row.title}
            </TableCell>
            <TableCell align="right">
                <CheckBox />
            </TableCell>
            <TableCell align="right">
                <CheckBox />
            </TableCell>
        </StyledTableRow>
    )
}

export default StaffAbilityItem