import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';
import { convertMoney, getOrderId, hasPermission } from '../../../../utils/utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ComissionItem = ({ comission, onChangeComission, auth }) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/crm/lead/${comission.lead_order.user.username}/${comission.lead_order.lead}`)
    }

    return (
        <StyledTableRow
            key={comission.id.toString()}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="right" onClick={openDetail} style={{ minWidth: '140px', cursor: 'pointer', color: 'blue', textDecorationLine: 'underline' }}>{getOrderId(comission.id)}</TableCell>
            <TableCell align="right" style={{ minWidth: '140px' }}>{comission.lead_order.user.full_name}</TableCell>
            <TableCell align="right" style={{ minWidth: '140px' }}>{convertMoney(comission.amount, true)}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px' }}>{dayjs(comission.payment_date).format('HH:mm:ss DD/MM/YYYY')}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px' }}>{`${comission.lead_order.sale1 ? comission.lead_order.sale1.full_name : ''} - ${convertMoney(comission.comission_sale1, true)}`}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px' }}>{`${comission.lead_order.sale2 ? comission.lead_order.sale2.full_name : ''} - ${convertMoney(comission.comission_sale2, true)}`}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px' }}>{`${comission.lead_order.telesale ? comission.lead_order.telesale.full_name : ''} - ${convertMoney(comission.comission_telesale, true)}`}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px' }}>
                <Stack sx={{ gap: '8px' }}>
                    <Button sx={{ fontSize: '11px' }} variant='text' onClick={openDetail} color='secondary'>Xem đơn</Button>
                    {hasPermission(auth, ['write_comission']) &&  <Button sx={{ fontSize: '11px' }} variant='text' onClick={() => onChangeComission && onChangeComission()}>Thay đổi</Button>}
                </Stack>
            </TableCell>
        </StyledTableRow>
    )
}

export default ComissionItem