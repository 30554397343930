import React from 'react'
import { Checkbox, Stack, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PermissionDetailItem = ({ permission, access, onChange }) => {

    const handleChange = (event) => {
        onChange(event.target.checked);
    }

    return (
        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', padding: '8px 16px' }}>
            <Stack sx={{ width: '100px', alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                    checked={access && access.indexOf(permission.permission) >= 0}
                    onChange={handleChange}
                    disabled={access && access.indexOf('crm_access') === -1 && permission.permission !== 'crm_access'}
                />
            </Stack>
            <Typography flex={1}>{permission.title}</Typography>

        </Stack>
    )
}

export default PermissionDetailItem