import React from 'react'
import { Box, Card, Divider, Stack } from '@mui/material'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

const ContactLeadItem = ({ lead, style, ...props }) => {
    const history = useHistory()
    const openDetail = () => {
        history.push(`/crm/lead/${lead.user.username}/${lead.id}`)
      }

    return (
        <Card onClick={openDetail} {...props} 
            style={{ ...style, cursor: 'pointer', display: 'flex', 
                    background: 'white', marginBottom: '16px', flexDirection: 'column',
                    marginRight: '8px'
                    }}>
            <Stack style={{margin: '12px'}}>
            <span style={{ fontSize: 12, fontWeight: '500' }}>Thông tin cơ bản</span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Tên khách hàng: '}
                {lead && lead.user && lead.user.full_name && <span style={{ color: 'blue', fontSize: 13 }}>{lead.user.full_name}</span>}</span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Số điện thoại: '}
                <span style={{ color: 'blue', fontSize: 13 }}>{lead.user.username}</span>
            </span>
            {/* <span style={{ fontSize: 11, marginTop: 8 }}>{'Dịch vụ: '}
                <span style={{ color: 'blue' }}>{lead.services.map((item) => item.name).join(', ')}</span>
            </span> */}
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Thời gian tạo: '}
                <span style={{ color: 'gray' }}>{dayjs(lead.created_time).format('HH:mm:ss DD/MM/YYYY')}</span>
            </span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{'Cập nhật gần nhất: '}
                <span style={{ color: 'gray' }}>{dayjs(lead.updated_time).format('HH:mm:ss DD/MM/YYYY')}</span>
            </span>
            <span style={{ fontSize: 11, marginTop: 8 }}>{`Cửa hàng: ${lead.shop.name}`}</span>
            <Divider style={{marginTop: '12px'}}/>
            <span style={{ fontSize: 12, fontWeight: '500', marginTop: '8px' }}>Nhân sự phụ trách</span>
            {
                lead.sale1 && 
                <span style={{ fontSize: 11, marginTop: 8 }}>{'Sale chính: '}
                    <span>{lead.sale1.full_name}</span>
                </span>
            }
            {
                lead.telesale && 
                <span style={{ fontSize: 11, marginTop: 8 }}>{'Telesale: '}
                    <span>{lead.telesale.full_name}</span>
                </span>
            }
            {
                lead.sale2 && 
                <span style={{ fontSize: 11, marginTop: 8 }}>{'Sale phụ: '}
                    <span >{lead.sale2.full_name}</span>
                </span>
            }
            </Stack>
        </Card>
    )
}

export default ContactLeadItem