import { Stack, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import GroupItem from './components/GroupItem';
import axios from 'axios';
import { useAtom } from 'jotai';
import { authAtom, sourcesAtom } from '../../../actions';
import AddGroupModal from './modals/AddGroupModal';
import { toast } from 'react-toastify';
import { hasPermission } from '../../../utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const Screen = () => {
    const [sources, setSources] = useAtom(sourcesAtom)
    const [addGroupModal, setAddGroupModal] = useState(null)
    const [authUser] = useAtom(authAtom)

    const getSources = useCallback(() => {
        axios.get('/accounts/sources/get_sources/')
            .then((response) => {
                if (response && response.data) {
                    setSources(response.data)
                }
            })
            .catch((error) => {
                console.log({ error })
            })
    }, [])

    React.useEffect(() => {
        getSources()
    }, [getSources])

    const onAddGroup = ({id, name, description}) => {
        axios.post('/accounts/sources/update_source/', {
            source_id: id, name: name, description: description
        })
            .then((response) => {
                if (response && response.data) {
                    getSources()
                    setAddGroupModal(null)
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    if(!(authUser && hasPermission(authUser, ['view_group', 'view_group_all']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Nguồn khách hàng' subTitle={`Tìm thấy ${sources.length} nguồn khách`} addButtonTitle='Thêm nhóm' onAddPress={() => setAddGroupModal({})} />
            <div style={{ display: "flex", width: 'calc(100vw - 230px)', marginTop: '24px', padding: '16px' }}>
                <Paper style={{ padding: '16px' }}>
                    <TableContainer component={Paper} style={{ overflowY: 'scroll', width: 'calc(100vw - 250px)', overflowX: 'scroll', maxHeight: '60vh' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Tên nhóm</StyledTableCell>
                                    <StyledTableCell align="right">Số khách hàng</StyledTableCell>
                                    <StyledTableCell align="right">Mô tả</StyledTableCell>
                                    <StyledTableCell align="right">Người tạo</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sources.map((item, index) => (
                                    <GroupItem source={item} onEdit={() => setAddGroupModal(item)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            {addGroupModal &&
                <AddGroupModal
                    handleAdd={onAddGroup}
                    handleClose={() => setAddGroupModal(null)}
                    source={addGroupModal}
                    open={addGroupModal !== null}
                />
            }
        </div>
    )
}

export default Screen