import { TableContainer, TablePagination, TableHead, TableRow, TableBody, Paper, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles'
import BookingItem from '../components/BookingItem';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const BookingTab = ({ username: lead_username }) => {
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(30)
    const [page, setPage] = useState(1)

    const [bookings, setBookings] = useState([])

    const getBookings = useCallback(() => {
        if (token) {
            axios.post('/accounts/customers/get_customer_bookings/', {
                username: lead_username,
                offset: page * rowsPerPage, limit: rowsPerPage,
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setBookings(res.data.bookings ?? [])
                        setTotal(res.data.total ?? 0)
                    } else {
                        setBookings([])
                        setTotal(0)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setBookings([])
                    setTotal(0)
                })
        }
    }, [token, lead_username, page, rowsPerPage])

    useEffect(() => {
        getBookings()
    }, [getBookings])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };


    return (
        <Stack spacing={3} sx={{ padding: '5px' }}>
            <TableContainer component={Paper} style={{ overflowY: 'scroll', width: 'calc(100vw - 250px)', overflowX: 'scroll', maxHeight: '60vh' }}>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 40, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Mã đặt</StyledTableCell>
                            <StyledTableCell>Ngày</StyledTableCell>
                            <StyledTableCell>Giờ</StyledTableCell>
                            <StyledTableCell>Liệu trình</StyledTableCell>
                            <StyledTableCell>Tư vấn</StyledTableCell>
                            <StyledTableCell>Kỹ thuật viên</StyledTableCell>
                            <StyledTableCell>Cơ sở</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bookings.map((item, index) => {
                                let staffs = []
                                if (item.technical_staff) {
                                    staffs.push(item.technical_staff.full_name)
                                }
                                if (item.second_technical_staff) {
                                    staffs.push(item.second_technical_staff.full_name)
                                }
                                return (
                                    <TableRow>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{dayjs(item.booking_date).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>{dayjs(item.booking_date).format('HH:mm')}</TableCell>
                                        <TableCell>{item.service_order.service_name ?? item.service_order.service.name}</TableCell>
                                        <TableCell>{item.advisor ? item.advisor.full_name : ''}</TableCell>
                                        <TableCell>{staffs.join(', ')}</TableCell>
                                        <TableCell>{item.booking_shop ? item.booking_shop.name : ''}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default BookingTab