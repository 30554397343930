import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StaffItem = ({service}) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/app/service/${service.id}`)
    }

    return(
        <StyledTableRow
              key={service}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={openDetail}
            >
              <TableCell component="th" scope="row">
                {service.name}
              </TableCell>
              <TableCell align="right">{service.price}</TableCell>
              <TableCell align="right">{service.service_duration}</TableCell>
              <TableCell align="right">{'500'}</TableCell>
            </StyledTableRow>
    )
}

export default StaffItem