import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';
import { PAYMENT_METHOD, convertMoney, getOrderId, hasPermission } from '../../../../utils/utils';
import { useAtomValue } from 'jotai';
import { authAtom } from '../../../../actions';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const PaymentItem = ({ payment, onConfirmPayment, changeComission }) => {
  const history = useHistory()
  const auth = useAtomValue(authAtom)

  const openDetail = () => {
    history.push(`/crm/lead/${payment.lead_order.user.username}/${payment.lead_order.lead}`)
  }

  let approvedBy = (
    <span></span>
  )
  let editButton = null

  if (payment.approved_by) {
    approvedBy = (
      <span>{payment.approved_by.full_name}</span>
    )
  } else if (hasPermission(auth, ['write_payment'])) {
    approvedBy = (
      <Button sx={{}} onClick={() => onConfirmPayment && onConfirmPayment()} variant='contained' color='secondary'>Xác nhận</Button>
    )

  }
  if (auth.account_type === 2 || auth.account_type === 3 || auth.account_type === 7 || auth.account_type === 8) {
    editButton = (
      <Button sx={{}} nClick={() => onConfirmPayment && onConfirmPayment()} variant='contained' color='secondary'>Thay đổi</Button>
    )
  }

  let staffs = []
  if (payment.order?.sale1) staffs.push(payment.order.sale1.full_name)
  if (payment.order?.sale2) staffs.push(payment.order.sale2.full_name)
  if (payment.order?.telesale) staffs.push(payment.order.telesale.full_name)

  return (
    <StyledTableRow
      key={payment.id.toString()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell >{`${payment.lead_order.user.full_name}`}<br /><a href={`/crm/contact/${payment.lead_order.user.username}`}>{payment.lead_order.user.username}</a></TableCell>
      <TableCell align="right">{payment.created_by.full_name}</TableCell>
      <TableCell align="right">{approvedBy}</TableCell>
      <TableCell onClick={openDetail} align="right" style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }}>{getOrderId(payment.lead_order.id)}</TableCell>
      <TableCell align="right">{convertMoney(payment.amount, true)}</TableCell>
      <TableCell align="right">{PAYMENT_METHOD[payment.payment_method]}</TableCell>
      <TableCell align="right">{dayjs(payment.payment_date).format('HH:mm DD/MM/YYYY')}</TableCell>
      <TableCell align="right">{payment.note}</TableCell>
      <TableCell align="right">{payment.lead_order.shop ? payment.lead_order.shop.name : ''}</TableCell>
      <TableCell onClick={() => onConfirmPayment && onConfirmPayment()} align="right">{editButton}</TableCell>
    </StyledTableRow>
  )
}

export default PaymentItem