import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Stack, Typography, Table } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import Button from '@mui/material/Button';
import PermissionDetailItem from './components/PermissionDetailItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { authAtom, tokenAtom } from '../../../actions';
import { useAtom, useAtomValue } from 'jotai';
import axios from 'axios'
import { toast } from 'react-toastify';
import { hasPermission } from '../../../utils/utils';

// crm_access,management_access,view_report,view_report_all,create_new_lead,view_lead,view_lead_all,write_lead_all,assign_lead,view_group_all,view_group,view_order,view_customer_all,view_customer,view_order_all,view_comission,write_payment,view_payment_all,view_payment,view_comission_all,write_comission,export_report_all,export_report_customer,export_report_order,export_report_payment,export_report_comission

const all_permissions = [
    {
        title: 'Quyền truy cập',
        items: [
            { id: 1, title: 'Truy cập CRM', parent: null, permission: 'crm_access' },
            { id: 21, title: 'Quyền quản trị, truy cập chức năng quản lý', parent: 1, permission: 'management_access' },
        ]
    },
    {
        title: 'Báo cáo',
        items: [
            { id: 2, title: 'Xem báo cáo của cá nhân', parent: 1, permission: 'view_report' },
            { id: 3, title: 'Xem báo cáo tất cả nhân viên', parent: 1, permission: 'view_report_all' },
            { id: 24, title: 'Xuất báo cáo chung', parent: 1, permission: 'export_report_all' },
        ]
    },
    {
        title: 'Quản lý Lead',
        items: [
            { id: 4, title: 'Tạo lead từ khách hàng mới', parent: 1, permission: 'create_new_lead' },
            // { id: 5, title: 'Tạo lead từ khách hàng cũ đã được gắn', parent: 1, permission: 'create_exist_lead' },
            { id: 6, title: 'Xem danh sách lead được gắn', parent: 1, permission: 'view_lead' },
            { id: 7, title: 'Xem danh sách tất cả lead', parent: 1, permission: 'view_lead_all' },
            { id: 8, title: 'Có quyền thay đổi telesale/sale của lead', parent: 1, permission: 'write_lead_all' },
            { id: 23, title: 'Hệ thống có thể gán Lead cho sale đó khi telesale chuyển trạng thái', parent: 1, permission: 'assign_lead' },
        ]
    },
    {
        title: 'Nhóm khách hàng',
        items: [
            { id: 9, title: 'Xem/tạo mới được nguồn khách', parent: 1, permission: 'view_group' },
            { id: 10, title: 'Xem/xoá được tất cả nguồn khách của mọi người', parent: 1, permission: 'view_group_all' },
        ]
    },
    {
        title: 'Khách hàng',
        items: [
            { id: 11, title: 'Xem được khách hàng được gắn', parent: 1, permission: 'view_customer' },
            { id: 12, title: 'Xem được tất cả khách hàng', parent: 1, permission: 'view_customer_all' },
            { id: 25, title: 'Xuất báo cáo khách hàng', parent: 1, permission: 'export_report_customer' },
        ]
    },
    {
        title: 'Đơn hàng',
        items: [
            { id: 13, title: 'Xem đơn hàng tạo bởi tài khoản đó', parent: 1, permission: 'view_order' },
            { id: 14, title: 'Xem được tất cả đơn hàng', parent: 1, permission: 'view_order_all' },
            { id: 26, title: 'Xuất báo cáo đơn hàng', parent: 1, permission: 'export_report_order' },
        ]
    },
    {
        title: 'Thanh toán',
        items: [
            { id: 15, title: 'Xem được thanh toán tạo bởi tài khoản đó', parent: 1, permission: 'view_payment' },
            { id: 16, title: 'Xem được tất cả thanh toán', parent: 1, permission: 'view_payment_all' },
            { id: 17, title: 'Xác nhận được thanh toán', parent: 1, permission: 'write_payment' },
            { id: 27, title: 'Xuất báo cáo thanh toán', parent: 1, permission: 'export_report_payment' },
        ]
    },
    {
        title: 'Hoa hồng',
        items: [
            { id: 18, title: 'Xem được hoa hồng của tài khoản đó', parent: 1, permission: 'view_comission' },
            { id: 19, title: 'Xem được tất cả hoa hồng', parent: 1, permission: 'view_comission_all' },
            { id: 20, title: 'Có quyền chia hoa hồng', parent: 1, permission: 'write_comission' },
            { id: 28, title: 'Xuất báo cáo hoa hồng', parent: 1, permission: 'export_report_comission' },
        ]
    },
]


const Screen = () => {
    const { username: staff_username } = useParams()
    const token = useAtomValue(tokenAtom)
    const [user, setUser] = useState({})
    const [permissions, setPermissions] = useState([])
    const [auth] = useAtom(authAtom)

    const getPermissions = useCallback(() => {
        if (token) {
            axios.post('/accounts/customers/get_permission/', {
                username: staff_username,
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data) {
                        setUser(response.data)
                        setPermissions(response.data.permissions ?? [])
                    } else {
                        setUser({})
                        setPermissions([])
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setUser({})
                    setPermissions([])
                })
        }
    }, [staff_username, token])

    useEffect(() => {
        getPermissions()
    }, [getPermissions])

    const onChangePermission = (permission, checked) => {
        console.log({ permission, checked })
        if (permission === 'crm_access' && !checked) {
            setPermissions([])
        } else {
            let newPermissions = [...permissions]
            const index = newPermissions.indexOf(permission)
            if (index >= 0) {
                newPermissions.splice(index, 1)
            }

            if (checked) {
                newPermissions.push(permission)
            }

            setPermissions(newPermissions)
        }
    }

    const onSave = () => {
        axios.post('/accounts/customers/update_permission/', {
            username: staff_username,
            permissions
        }, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then((response) => {
                if (response && response.data) {
                    toast.success('Cập nhật thành công')
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                console.log({ error })
                toast.error('Cập nhật thất bại')
            })
    }

    if(!(auth && hasPermission(auth, ['management_access']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Stack sx={{ margin: '16px', gap: '32px' }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <h4>{`Chi tiết phân quyền - ${user.full_name ?? ''} - ${user.username ?? ''}`}</h4>
                    <Button onClick={onSave} sx={{ height: '50px' }} variant='contained'>Lưu thay đổi</Button>
                </Stack>
                <Stack>
                    {all_permissions.map((group) => (
                        <>
                            <Stack direction='row' sx={{ background: 'black', justifyContent: 'space-between', padding: '16px 8px' }}>
                                <Typography sx={{ color: 'white', width: '110px', alignItems: 'center', textAlign: 'center' }}></Typography>
                                <Typography sx={{ color: 'white', flex: 1 }}>{group.title}</Typography>

                            </Stack>
                            {
                                group.items.map((permission) => (
                                    <PermissionDetailItem
                                        permission={permission}
                                        access={permissions}
                                        onChange={(checked) => onChangePermission(permission.permission, checked)}
                                    />
                                ))
                            }
                        </>
                    ))}
                </Stack>
            </Stack>
        </div>
    )
}

export default Screen