import React from 'react'
import { Stack, Box, Divider, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { authAtom } from '../../../../actions'
import dayjs from 'dayjs'

const TransactionItem = ({ updated_time, staff, note, onEdit }) => {
    const [auth] = useAtom(authAtom)

    return (
        <Stack flexDirection='row'>
            <Stack style={{ flex: 1 }}>
                <Box>
                    <p>{`Thời gian: `}<strong>{dayjs(updated_time).format('HH:mm DD/MM/YYYY')}</strong></p>
                    <p>{`Người thêm: ${staff.full_name}`}</p>
                    <p>{`Ghi chú: ${note ?? ''}`}</p>
                </Box>
                <Divider />
            </Stack>
        </Stack>
    )
}

export default TransactionItem