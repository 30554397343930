import { Button, Box, FormControl, Input, InputLabel, TableHead, Stack, Table, TablePagination, Typography } from '@mui/material'
import React, { useCallback, useRef, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { Download, FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import OrderItem from './components/OrderItem';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { authAtom, shopsAtom, staffsAtom, tokenAtom } from '../../../actions';
import { useAtom, useAtomValue } from 'jotai';
import axios from 'axios';
import dayjs from 'dayjs';
import DateRangeModal from '../Payments/modals/DateRangeModal';
import AddComissionModal from '../Leads/modals/AddComissionModal';
import { toast } from 'react-toastify';
import { hasPermission, showConfirmationToast, convertMoney, getOrderId, LEAD_ORDER_STATUS } from '../../../utils/utils';
import * as XLSX from 'xlsx'
import { MultiSelect } from "react-multi-select-component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        zIndex: 1
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const Screen = () => {
    const [orders, setOrders] = useState([])
    const shops = useAtomValue(shopsAtom)
    const [token] = useAtom(tokenAtom)
    const [total, setTotal] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [page, setPage] = useState(0)
    const [dateRangeModal, setDateRangeModal] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').format())
    const [endDate, setEndDate] = useState(dayjs().format())
    const [shop, setShop] = useState(0)
    const [keyword, setKeyword] = useState('')
    const [status, setStatus] = useState(0)
    const staffs = useAtomValue(staffsAtom)
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const authUser = useAtomValue(authAtom)
    const [changeComission, setChangeComissionModal] = useState(null)
    const toastId = useRef(null)

    const onChangeRange = (range) => {
        setStartDate(range.startDate)
        setEndDate(range.endDate)
        setDateRangeModal(false)
    }

    const refreshOrder = useCallback(() => {
        console.log({token})
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`
            
            axios.post('/booking/lead_order/all_orders/', {
                offset: page * rowsPerPage,
                limit: rowsPerPage,
                shop: shop > 0 ? shop : null,
                staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                status: status,
                from_date: dayjs(startDate).startOf('day').format(), to_date: dayjs(endDate).endOf('day').format(),
                keyword
            })
                .then((response) => {
                    if (response && response.data) {
                        setOrders(response.data.orders)
                        setTotal(response.data.total)
                        setTotalPayment(response.data.totalPayment ?? 0)
                        setTotalPrice(response.data.totalPrice ?? 0)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }

    }, [token, page, rowsPerPage, startDate, endDate, selectedStaffs, keyword, shop, status])

    useEffect(() => {
        refreshOrder()
    }, [refreshOrder])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeKeyword = (e) => {
        setPage(0)
        setKeyword(e.target.value)
    }

    const onChangeComission = ({ order_id, sale1, sale2, telesale }) => {
        axios.post('/booking/comissions/update_comission/', {
            order_id, sale1, sale2, telesale
        })
            .then((response) => {
                if (response && response.data) {
                    const filter = orders.filter((item) => item.id === order_id)
                    if (filter && filter.length > 0) {
                        const index = orders.indexOf(filter[0])
                        const newOrders = orders
                        let newOrder = orders[index]
                        newOrder.comission = response.data
                        newOrders.splice(index, 1, newOrder)
                        setOrders(newOrders)
                    }
                    toast.success('Xác nhận thành công')
                    setChangeComissionModal(null)
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onDownload = () => {
        showConfirmationToast(`Chắc chắn tải về danh sách đơn hàng từ ${dayjs(startDate).format('DD/MM/YYYY')} đến ${dayjs(endDate).format('DD/MM/YYYY')}?`,
            () => {
                setTimeout(() => {
                    toastId.current = toast.loading("Đang tải dữ liệu")
                    axios.post('/booking/lead_order/download_all_orders/', {
                        shop: shop > 0 ? shop : null,
                        staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                        status: status,
                        from_date: dayjs(startDate).startOf('day').format(), to_date: dayjs(endDate).endOf('day').format(),
                        keyword
                    })
                        .then((response) => {
                            try {
                                if (response && response.data) {
                                    const report = response.data.map((item) => {
                                        let totalPrice = 0
                                        let totalPaid = 0
                                        const serviceName = item.orders.map((item) => item.service.name).join(', ')
                                        for (const orderItem of item.orders) {
                                            totalPrice += orderItem.price
                                        }
                                        for (const paymentItem of item.payments ?? []) {
                                            totalPaid += paymentItem.amount
                                        }

                                        return ({
                                            'order': getOrderId(item.id),
                                            'date': dayjs(item.order_date).format('HH:mm:ss DD/MM/YYYY'),
                                            'full_name': item.user.full_name,
                                            'username': item.user.username,
                                            'services': serviceName,
                                            'totalPrice': totalPrice ?? 0,
                                            'totalPaid': totalPaid ?? 0,
                                            'shop': item.shop ? item.shop.name : '',
                                            'status': LEAD_ORDER_STATUS[item.status],
                                            'sale1': item.sale1 ? item.sale1.full_name : '',
                                            'sale1Commission': item.comission && item.comission.sale1 ? item.comission.sale1 : 0,
                                            'sale2': item.sale2 ? item.sale2.full_name : '',
                                            'sale2Commission': item.comission && item.comission.sale2 ? item.comission.sale2 : 0,
                                            'telesale': item.telesale ? item.telesale.full_name : '',
                                            'telesaleCommission': item.comission && item.comission.telesale ? item.comission.telesale : 0,
                                        })
                                    })
                                    const worksheet = XLSX.utils.json_to_sheet(report, { origin: 'A2' });
                                    const headers = ['Đơn hàng', 'Ngày tạo đơn', "Khách hàng", 'Số điện thoại', 'Liệu trình', 'Tổng giá trị đơn', 'Đã thành toán', 'Cửa hàng', 'Trạng thái', 'Sale 1', 'Hoa hồng sale1', 'Sale 2', 'Hoa hồng sale2', 'Telesale', 'Hoa hồng telesale', ]
                                    worksheet['!merges'] = [
                                        { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length } },
                                    ]
                                    XLSX.utils.sheet_add_aoa(worksheet, [[`Danh sách đơn hàng từ ${dayjs(startDate).format('DD/MM/YYYY')} đến ${dayjs(endDate).format('DD/MM/YYYY')}`]], { origin: "A1" });
                                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A2" });
                                    const workbook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                                    XLSX.writeFile(workbook, `Danh sách đơn hàng.xlsx`);

                                    toast.dismiss(toastId.current)
                                    toast.success('Tải về thành công')
                                }
                            } catch (error) {
                                console.log({ error })
                                toast.dismiss(toastId.current)
                                toast.error('Lỗi tải về')
                            }
                        })
                        .catch((error) => {
                            console.log({ error })
                            toast.dismiss(toastId.current)
                            toast.error('Lỗi tải về')
                        })
                }, 500);
            }, () => { })
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))
    // staffOptions.unshift({ label: 'Tất cả', id: 0 })

    if (!(authUser && hasPermission(authUser, ['view_order', 'view_order_all']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách đơn hàng' subTitle={`Tìm thấy ${total} đơn hàng`} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '24px', marginRight: '24px' }}>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'enter', justifyContent: 'space-between', width: '100%' }}>
                    <Stack gap={'12px'} sx={{ flex: 1 }}>
                        <FormControl sx={{ width: '100%' }} variant='standard'>
                            <InputLabel sx={{ fontSize: '12px' }}>Tên khách hàng, số điện thoại</InputLabel>
                            <Input
                                value={keyword} onChange={handleChangeKeyword}
                                size='small' style={{ width: '300px' }}
                                placeholder="Tên khách hàng, số điện thoại"
                            />
                        </FormControl>
                    </Stack>
                    <Stack gap={'16px'} sx={{ alignItems: 'flex-end' }}>
                        <Stack gap={'8px'} direction='row'>
                            <Stack sx={{ width: '160px', cursor: 'pointer', border: '0.5px solid #cccccc', padding: '3px 5px', borderRadius: '5px' }} variant='standard' onClick={() => setDateRangeModal(true)}>
                                <InputLabel sx={{ fontSize: '10px' }}>Thời gian</InputLabel>
                                <Typography sx={{ fontSize: '12px' }}>{`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}</Typography>
                            </Stack>
                            {/* <FormControl sx={{ width: '150px', }} variant='standard'>
                                <InputLabel sx={{ fontSize: '12px' }}>Cửa hàng</InputLabel>
                                <Select sx={{ fontSize: '12px' }}
                                    defaultValue={null} value={shop}
                                    onChange={(e) => setShop(e.target.value)} label='Cửa hàng'>
                                    <MenuItem value={0}>{'Tất cả'}</MenuItem>
                                    {
                                        shops.map((item) => ({ label: item.name, id: item.id })).map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: '160px' }} variant='standard'>
                                <InputLabel sx={{ fontSize: '12px' }}>Trạng thái đơn</InputLabel>
                                <Select sx={{ fontSize: '12px' }}
                                    defaultValue={null} value={status}
                                    onChange={(e) => setStatus(e.target.value)} label='Trạng thái đơn'>
                                    {
                                        [
                                            { label: 'Đã huỷ', id: -1 },
                                            { label: 'Chưa thanh toán đầy đủ', id: 0 },
                                            // { label: 'Đặt thành công', id: 1 },
                                            { label: 'Đã xác nhận', id: 2 },
                                        ].map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl> */}
                            {
                                hasPermission(authUser, ['view_order_all']) && (
                                    <Stack sx={{ width: '250px', zIndex: 20, fontSize: '12px' }}>
                                        <MultiSelect
                                            options={staffOptions}
                                            value={selectedStaffs}
                                            onChange={setSelectedStaffs}
                                            labelledBy="Chọn nhân viên"
                                            overrideStrings={{
                                                "allItemsAreSelected": "Tất cả",
                                                "clearSearch": "Xoá",
                                                "clearSelected": "Bỏ chọn",
                                                "search": "Tìm kiếm",
                                                "selectAll": "Chọn tất cả",
                                                "selectAllFiltered": "Chọn tất cả",
                                                "selectSomeItems": "Lựa chọn nhân viên"
                                            }}
                                        />
                                    </Stack>
                                )
                            }
                        </Stack>

                    </Stack>
                </Box>
            </Box>
            <div style={{ display: "flex", width: 'calc(100vw - 230px)', marginTop: '24px', padding: '16px' }}>
                <Paper style={{ padding: '16px' }}>
                    <Stack direction='row' justifyContent='flex-end' spacing='16px'>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40, 50]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {hasPermission(authUser, ['export_report_order']) && <Button onClick={onDownload}><Download /></Button>}
                    </Stack>
                    <TableContainer component={Paper} style={{ overflowY: 'scroll', width: 'calc(100vw - 250px)', overflowX: 'scroll', maxHeight: '60vh' }}>
                        <Table stickyHeader >
                            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                <TableRow>
                                    <StyledTableCell style={{ minWidth: '60px' }}>Mã đơn</StyledTableCell>
                                    <StyledTableCell style={{ minWidth: '80px' }}>Ngày tạo</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>Khách hàng</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>Liệu trình</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '80px' }}>Nhân viên</StyledTableCell>
                                    {/* <StyledTableCell align="right" style={{ minWidth: '80px' }}>Hoa hồng</StyledTableCell> */}
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>Tổng giá trị</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>Đã thanh toán</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '80px' }}>Cửa hàng</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '80px', maxWidth: '80px' }}>Trạng thái đơn</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell style={{ minWidth: '60px' }} colSpan={5}>Tổng cộng</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>{`${convertMoney(totalPrice, true)}`}</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '120px' }}>{`${convertMoney(totalPayment, true)}`}</StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '100px' }}></StyledTableCell>
                                    <StyledTableCell align="right" style={{ minWidth: '100px', maxWidth: '80px' }}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order, index) => (
                                    <OrderItem order={order} key={`${index}-${order.id.toString()}`} changeComission={() => setChangeComissionModal(order)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

            {changeComission !== null &&
                <AddComissionModal
                    open={changeComission !== null}
                    onChangeComission={onChangeComission}
                    order={changeComission}
                    handleClose={() => {
                        setChangeComissionModal(null)
                    }}
                />
            }

            <DateRangeModal
                open={dateRangeModal}
                startDate={startDate}
                endDate={endDate}
                onConfirm={onChangeRange}
                handleClose={() => {
                    setDateRangeModal(false)
                }}
            />
        </div>
    )
}

export default Screen