import React from 'react'
import { Stack, Button, Select, MenuItem } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { ACCOUNT_TYPE, hasPermission } from '../../../../utils/utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AccountItem = ({ staff, onDelete, changeAccountType, onEdit }) => {
  const accessCRM = hasPermission(staff, ['crm_access'])
  return (
    <StyledTableRow
      key={staff.username}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {staff.full_name}
      </TableCell>
      <TableCell align="right">{staff.username}</TableCell>
      <TableCell align="right">
        <Select
          label="Vị trí"
          value={staff.account_type}
          onChange={(e) => changeAccountType && changeAccountType(e.target.value)}
          variant='standard'
          sx={{ fontSize: '12px', width: '100px' }}
        >
          <MenuItem value={0}>Tất cả</MenuItem>
          <MenuItem value={2}>Quản lý</MenuItem>
          <MenuItem value={3}>Tổng quản lý</MenuItem>
          <MenuItem value={4}>Sale</MenuItem>
          <MenuItem value={5}>Telesale</MenuItem>
          <MenuItem value={6}>Kỹ thuật viên</MenuItem>
          <MenuItem value={7}>Kế toán</MenuItem>
          <MenuItem value={8}>Lễ tân</MenuItem>
          <MenuItem value={9}>Y tá</MenuItem>
          <MenuItem value={10}>Phòng răng</MenuItem>
          <MenuItem value={11}>Makerting</MenuItem>
          <MenuItem value={12}>Phòng tóc</MenuItem>
          <MenuItem value={13}>Bác sĩ</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right" sx={{ color: accessCRM ? 'green' : 'red' }}>{accessCRM ? 'Có quyền' : 'Không'}</TableCell>
      <TableCell align="right">
        <Stack direction='row' spacing='16px' justifyContent='flex-end'>
          <Button onClick={() => onDelete && onDelete()} color='error' variant='contained'>Xoá</Button>
          {/* <Button onClick={() => onEdit && onEdit()} color='info' variant='contained'>Cập nhật</Button> */}
        </Stack>
      </TableCell>
    </StyledTableRow>
  )
}

export default AccountItem