import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const DealItem = ({ row }) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/crm/group/${row}`)
    }

    return (
        <StyledTableRow
            key={row}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={openDetail}
            style={{ cursor: 'pointer' }}
        >
            <TableCell>{dayjs().format('HH:mm:ss DD/MM/YYYY')}</TableCell>
            <TableCell align="right">Phẫu thuật ngực việt</TableCell>
            <TableCell align="right">100.000.000đ</TableCell>
            <TableCell align="right">20</TableCell>
            <TableCell align="right">Nguyễn Quỳnh Anh</TableCell>
            <TableCell>{'Phẫu thuật ngực việt'}</TableCell>
            <TableCell align="right">Hải Yến</TableCell>
        </StyledTableRow>
    )
}

export default DealItem