import { Autocomplete, Badge, Box, Button, Divider, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import LeadItem from '../components/LeadItem';

const steps = [
    'Chưa có tiến trình',
    'Telesale',
    'Khách quan tâm',
    'Đã đặt lịch tư vấn',
    'Khách mua liệu trình',
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const LeadTab = ({ username: lead_username }) => {
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()

    const [leads, setLeads] = useState([])



    const getLeads = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_customer_leads/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setLeads(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message)
                        toast.error(error.response.data.message)
                    else
                        toast.error('Không tìm thấy thông tin lead')
                })
    }, [lead_username, token])

    useEffect(() => {
        getLeads()
    }, [getLeads,])

    const onCreateNewLead = () => {

        axios.post('/accounts/leads/add_customer_lead/', {
            username: lead_username
        })
            .then((res) => {
                if (res && res.data) {
                    history.push(`/crm/lead/${lead_username}/${res.data.id}`)
                } else {
                    toast.error('Tạo lead không thành công')
                }
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Tạo lead không thành công')
            })

    }


    return (
        <Stack spacing={3} sx={{ padding: '5px' }}>
            <Button variant='contained' onClick={onCreateNewLead}>Tạo lead mới</Button>
            {
                leads.map((item, index) => (
                    <LeadItem
                        lead={item}
                        key={index.toString()}
                    />
                ))
            }
        </Stack>
    )
}

export default LeadTab