import { Autocomplete, Badge, Box, Button, Divider, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import VoucherItem from '../components/VoucherItem';

const VoucherTab = ({ username: lead_username }) => {
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()

    const [vouchers, setVouchers] = useState([])

    const getVouchers = useCallback(() => {
        if (token)
            axios.post('/services/gift/get_gifts/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    console.log({ res })
                    if (res && res.data && res.data.source) {
                        setVouchers(res.data.source.id)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [token, lead_username])


    useEffect(() => {
        getVouchers()
    }, [getVouchers,])


    return (
        <Stack spacing={3} sx={{ padding: '5px' }}>
            {
                vouchers.map((item, index) => (
                    <VoucherItem
                        voucher={item}
                        key={index.toString()}
                    />
                ))
            }
        </Stack>
    )
}

export default VoucherTab