import React, { useRef, useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Divider, Typography, IconButton } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import { convertMoney, showConfirmationToast } from '../../../../utils/utils';
import axios from 'axios'
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '5%',
    bottom: '5%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpdateOrderModal = ({ order, open, handleClose, handleUpdate }) => {
    const [_note, setNote] = useState(order.note ?? '')
    const [_service_name, setServiceName] = useState(order.service_name ?? order.service.name)
    const [_price, setPrice] = useState(order.price ?? 0)
    const [_amount, setAmount] = useState(order.number_of_booking ?? 0)

    const onClear = () => {
        setNote('')
        setPrice(0)
        setAmount(0)
        setServiceName('')
    }

    const onAdd = () => {

        try {
            handleUpdate({ order_id: order.id, service_name: _service_name, price: parseInt(_price.toString()), amount: parseInt(_amount.toString()), note: _note })
            setTimeout(onClear, 500);
        } catch (error) {
            toast.error('Lỗi với giá tiền và số buổi')
        }
    }

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Cập nhật đơn hàng</h4>
                <Stack style={{ gap: '24px' }}>
                    <Typography>{`Liệu trình gốc: ${order.service.name}`}</Typography>
                    <TextField
                        label="Tên liệu trình hiện tại"
                        fullWidth
                        variant="standard" placeholder='Tên liệu trình hiện tại' value={_service_name}
                        onChange={(e) => {
                            setServiceName(e.target.value)
                        }}
                    />

                    <TextField
                        label="Số buổi"
                        type='number'
                        variant="standard" placeholder='Số buổi' value={_amount}
                        onChange={(e) => {
                            setAmount(e.target.value)
                        }}
                    />


                    <NumericFormat
                        customInput={TextField}
                        label="Giá tiền"
                        thousandSeparator='.'
                        decimalSeparator=','
                        type='text'
                        suffix='đ'
                        variant="standard" placeholder='Giá tiền' value={_price}
                        onValueChange={(values) => setPrice(parseFloat(values.value))}
                        style={{ flex: 1 }}
                    />

                    <TextField
                        label="Ghi chú"
                        fullWidth
                        multiline
                        rows={2}
                        variant="standard" placeholder='Ghi chú' value={_note}
                        onChange={(e) => {
                            setNote(e.target.value)
                        }}
                    />

                    <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                        <Button onClick={onClose} color='error' style={{ flex: 1 }}>Huỷ</Button>
                        <Button variant='contained' onClick={onAdd} color='success' style={{ flex: 1 }}>Cập nhật</Button>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default UpdateOrderModal