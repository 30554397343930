import { Autocomplete, Badge, Box, Button, Divider, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';

import WalletItem from '../components/WalletItem';

const WalletTab = ({ username: lead_username }) => {
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()

    const [wallets, setWallets] = useState([])



    const getWallets = useCallback(() => {
        if (token) {
            axios.post('/accounts/users/get_user_transactions/', {
                username: lead_username
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setWallets(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }, [token, lead_username])

    useEffect(() => {
        getWallets()
    }, [getWallets])



    return (
        <Stack spacing={3} sx={{ padding: '5px' }}>
            {
                wallets.map((item, index) => (
                    <WalletItem
                        wallet={item}
                        key={index.toString()}
                    />
                ))
            }
        </Stack>
    )
}

export default WalletTab