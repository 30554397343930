import {atom} from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const tokenAtom = atomWithStorage('token', undefined)
export const authAtom = atomWithStorage('auth', undefined)
export const leadsAtom = atomWithStorage('leads', [])
export const ordersAtom = atomWithStorage('orders', [])
export const paymentsAtom = atomWithStorage('payments', [])
export const comissionsAtom = atomWithStorage('comissions', [])
export const notesAtom = atomWithStorage('notes', [])
export const transactionsAtom = atomWithStorage('transactions', [])
export const customersAtom = atomWithStorage('customers', [])

//const
export const shopsAtom = atomWithStorage('shops', [])
export const sourcesAtom = atomWithStorage('sources', [])
export const servicessAtom = atomWithStorage('services', [])
export const staffsAtom = atomWithStorage('staffs', [])