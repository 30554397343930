import React, { useState } from 'react'
import { Stack, Autocomplete, Button, Modal, TextField } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { viVN } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/vi';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ChangeOrderDateModal = ({ order, open, handleUpdate, handleClose }) => {
    console.log({order})
    const [_time, setTime] = useState(order && order.order_date ? dayjs(order.order_date) : dayjs())

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (handleUpdate && _time) {
            handleUpdate({ order_date: dayjs(_time).format(), lead_order_id: order?.id})
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thay đổi thời gian đơn hàng</h4>
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={viVN.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale='vi'>
                    <DemoContainer
                        components={[
                            'StaticDateTimePicker',
                            'MobileDateTimePicker',
                        ]}
                    >
                        <DemoItem
                        label='Thời gian đặt'>
                            <MobileDateTimePicker value={_time} onChange={(value) => setTime(value)} format='HH:mm DD/MM/YYYY' label='Thời gian đặt' />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!_time} onClick={onAdd} color='success' style={{ flex: 1 }}>Cập nhật</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default ChangeOrderDateModal