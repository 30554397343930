import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { convertMoney } from '../../../../utils/utils';

const ProductItem = ({ customer }) => {
  const history = useHistory()
  const openDetail = () => {
    history.push(`/crm/contact/${customer.username}`)
  }

  const staffs = []
  if (customer.staffs) {
    if (customer.staffs.sale1) {
      staffs.push(customer.staffs.sale1.full_name)
    }
    if (customer.staffs.sale2) {
      staffs.push(customer.staffs.sale2.full_name)
    }
    if (customer.staffs.telesale) {
      staffs.push(customer.staffs.telesale.full_name)
    }
  }
  const staffName = staffs.join(', ')

  return (
    <TableRow
      key={customer.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onClick={openDetail}
    >
      <TableCell component="th" scope="row">
        {customer.full_name}
      </TableCell>
      <TableCell align="right">{customer.username}</TableCell>
      <TableCell align="right">{customer.totalOrder}</TableCell>
      <TableCell align="right">{convertMoney(customer.totalPrice, true)}</TableCell>
      <TableCell align="right">{staffName}</TableCell>
    </TableRow>
  )
}

export default ProductItem