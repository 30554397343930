import { Autocomplete, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import TaskItem from './components/TaskItem';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';

const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});


const Screen = () => {
    const [state, setState] = useState([
        { title: 'Chưa bắt đầu', items: getItems(10, 10) },
        { title: 'Đang tiến hành', items: getItems(10, 20) },
        { title: 'Đã hoàn thành', items: getItems(10, 30) },
        { title: 'Đã huỷ', items: getItems(10, 40) },
    ]);

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        console.log({ check: state[sInd] })

        if (sInd === dInd) {
            const items = reorder(state[sInd].items, source.index, destination.index);
            const newState = [...state];
            newState[sInd].items = items;
            setState(newState);
        } else {
            const result = move(state[sInd].items, state[dInd].items, source, destination);
            const newState = [...state];
            newState[sInd].items = result[sInd].items;
            newState[dInd].items = result[dInd].items;

            setState(newState.filter(group => group.length));
        }
    }

    const onAddTask = () => {

    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách công việc' subTitle='Tìm thấy: 100 tasks' addButtonTitle='Thêm Task Mới' onAddPress={onAddTask} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>
                {/* <Box sx={{ display: 'flex' }}>
                    <Box style={{ color: 'blue', display: 'flex', height: '36px', alignItems: 'center', justifyContent: 'center', width: '100px', marginRight: '16px', border: '1px solid #0000ff', borderRadius: '4px' }}>
                        <FilterAlt fontSize='13px' color='blue' />
                        <span style={{ color: 'blue', fontSize: '12px' }}>Bộ lọc</span>
                    </Box>
                    <TextField size='small' style={{ height: '32px', width: '320px' }} placeholder="Tên khách hàng, Email, số điện thoại" />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={['Thu Hà', 'Hải Yến']}
                        sx={{ width: '180px', marginLeft: '8px', marginRight: '8px'}}
                        renderInput={(params) => <TextField style={{ height: '36px', fontSize: '12px', color: 'black' }} size='small' {...params} label="Nhân sự phụ trách" />}
                    />
                </Box> */}
            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {state.map((el, ind) => (
                        <Droppable key={ind} droppableId={`${ind}`}>
                            {(provided, snapshot) => (
                                <div style={{ height: '100%', background: '#DAF5FF', marginRight: '8px', marginLeft: "8px", borderRadius: '4px', }}>
                                    <p style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '8px', fontSize: 15, fontWeight: '500' }}>{el.title}</p>
                                    <div
                                        ref={provided.innerRef}
                                        style={{ ...getListStyle(snapshot.isDraggingOver), borderRadius: 4, marginLeft: 8, marginRight: 8, overflowY: 'scroll', height: 'calc(100%-300px)' }}
                                        {...provided.droppableProps}
                                    >

                                        {el.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <TaskItem ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )} />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </div>
    )
}

export default Screen