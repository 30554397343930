import React, { useRef, useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Divider, Typography, IconButton } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import { convertMoney, showConfirmationToast } from '../../../../utils/utils';
import axios from 'axios'
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '5%',
    bottom: '5%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const NewService = ({ service, note = '', user_id, services, handleAdd }) => {
    const [_service, setService] = useState(service)
    const [_note, setNote] = useState(note)
    const [_service_name, setServiceName] = useState('')
    const [_price, setPrice] = useState(0)
    const [_amount, setAmount] = useState(0)
    const [keyword, setKeyword] = useState(null)
    const [orderId, setOrderId] = useState('')

    const onClear = () => {
        setService(null)
        setNote('')
        setPrice(0)
        setAmount(0)
        setServiceName('')
        setKeyword(null)
    }

    const onAdd = () => {
        if (!_service) {
            toast.error('Chưa chọn dịch vụ')
            return
        }

        try {
            console.log({service: _service, service_name: _service_name, price: _price, amount: _amount, note: _note })
            handleAdd({ service: _service, service_name: _service_name, price: parseInt(_price.toString()), amount: parseInt(_amount.toString()), note: _note })
            setTimeout(onClear, 500);
        } catch (error) {
            toast.error('Lỗi với giá tiền và số buổi')
        }
    }

    const searchOrder = () => {
        axios.get(`/booking/order/${orderId}/`)
            .then((res) => {
                console.log({ res, user_id })
                if(res && res.data && res.data.user && res.data.user.id === user_id) {
                    showConfirmationToast(`Đã tìm thấy đơn hàng với liệu trình ${res.data.service.name} số tiền ${convertMoney(res.data.price, true)}, mua vào ${dayjs(res.data.order_date).format('HH:mm DD/MM/YYYY')}. Xác nhận đúng liệu trình?`,
                    () => {
                        handleAdd({ orderId: res.data.id, service: res.data.service, service_name: res.data.service.name, price: parseInt(res.data.price), amount: parseInt(res.data.number_of_booking), note: res.data.note })
                    },
                    () => {

                    })
                } else {
                    toast.error('Đơn hàng không đúng khách hàng này')
                }
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Không tìm thấy đơn hàng')
            })
    }

    return (
        <Stack spacing='16px' style={{ background: '#eeeeee', padding: '8px', borderRadius: '5px', flex: 1, }}>
            <Stack flex={1} style={{gap: '8px'}}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={(services ?? [])}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField value={keyword} onChange={(e) => setKeyword(e.target.value)} {...params} label="Chọn liệu trình" />}
                onChange={(e, newValue) => {
                    if (newValue && newValue.id) {
                        setService(newValue)
                        setServiceName(newValue.name)
                        setAmount(newValue.total_time ?? 0)
                        setPrice(newValue.price ?? 0)
                    }
                }}
            />

            <TextField
                label="Tên liệu trình hiện tại"
                fullWidth
                variant="standard" placeholder='Tên liệu trình hiện tại' value={_service_name}
                onChange={(e) => {
                    setServiceName(e.target.value)
                }}
            />

            <Stack direction='row'>
                <TextField
                    label="Số buổi"
                    type='number'
                    variant="standard" placeholder='Số buổi' value={_amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                    }}
                    style={{ width: '80px' }}
                />

                <NumericFormat
                    customInput={TextField}
                    label="Giá tiền"
                    thousandSeparator='.'
                    decimalSeparator=','
                    type='text'
                    suffix='đ'
                    variant="standard" placeholder='Giá tiền' value={_price}
                    onValueChange={(values) => setPrice(parseFloat(values.value))}
                    style={{ flex: 1 }}
                />
            </Stack>

            <TextField
                label="Ghi chú"
                fullWidth
                multiline
                rows={2}
                variant="standard" placeholder='Ghi chú' value={_note}
                onChange={(e) => {
                    setNote(e.target.value)
                }}
            />

            <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                <Button onClick={onClear} color='error' style={{ flex: 1 }}>Xoá</Button>
                <Button disabled={!_service} onClick={onAdd} color='success' style={{ flex: 1 }}>Thêm liệu trình</Button>
            </Stack>
            </Stack>

            <Stack direction='row' style={{marginTop: 16, gap: '8px', justifyContent: 'space-between'}}>
                <TextField
                    label="Nhập liệu trình đã có sẵn"
                    variant="standard" placeholder='Nhập liệu trình đã có sẵn' value={orderId}
                    onChange={(e) => {
                        setOrderId(e.target.value)
                    }}
                />
                <Button variant='outlined' onClick={searchOrder}>Tìm</Button>
            </Stack>
        </Stack>
    )
}

const AddOrderModal = ({ services, open, handleClose, onCreateOrder, user_id }) => {
    const [items, setItems] = useState([])

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onCreate = () => {
        if (onCreateOrder) {
            onCreateOrder(items)
            setItems([])
        }
    }

    const onAddItem = ({ service, service_name, note, price, amount, orderId }) => {
        setItems((old) => [{ service, service_name, note, price, amount, orderId }, ...old])
    }

    const onRemove = (index) => {
        const newItems = [...items]
        newItems.splice(index, 1)
        setItems(newItems)
    }

    let totalPrice = 0
    for (const item of items) {
        totalPrice += item.price
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Tạo đơn hàng</h4>
                <Stack direction='row' spacing='8px' flex={1}>
                    <NewService
                        services={services}
                        handleAdd={onAddItem}
                        user_id={user_id}
                    />
                    <Divider style={{ height: '100%', background: '#eeeeee', width: '1px' }} />

                    <Stack flex={1}>
                        <h5>Liệu trình đã được thêm</h5>
                        <Stack style={{ flex: 1, maxHeight: '60%', overflowY: 'scroll' }} >
                            {
                                items.map((item, index) => {
                                    const { service, service_name, note, price, amount } = item
                                    return (
                                        <Stack direction='row' key={`service-order-${index}-${service.id}`} style={{ padding: '5px 0px', borderBottom: '1px solid #eeeeee', margin: '5px 0px' }}>
                                            <Stack flex={1}>
                                                <Typography fontSize='13px'>{`Dịch vụ: ${service_name}`}</Typography>
                                                <Typography fontSize='13px'>{`Giá tiền: ${convertMoney(price)} (${amount} buổi)`}</Typography>
                                                <Typography fontSize='13px'>{`Ghi chú: ${note}`}</Typography>
                                            </Stack>
                                            <IconButton onClick={() => onRemove(index)} aria-label='delete' color='error'>
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                        <Stack style={{ marginTop: '16px', width: '100%', borderTop: '1px solid #eeeeee', padding: '8px 0px 0px 0px' }} spacing='8px'>
                            <Typography fontSize='13px' width='100%' textAlign='right'>{`Tổng giá trị: ${convertMoney(totalPrice, true)}`}</Typography>
                            <Stack flexDirection='row' alignItems='center' justifyContent='center'>
                                <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                                <Button disabled={items.length === 0} variant='contained' onClick={onCreate} color='success' style={{ flex: 1 }}>Tạo đơn hàng</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddOrderModal