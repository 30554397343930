import { toast } from 'react-toastify';
import { Stack, Button, Typography } from '@mui/material'

export const getOrderId = (id) => {
    return `#${id.toString().padStart(6, '0')}`
}

export const ACCOUNT_TYPE = {
    '1': 'Khách hàng',
    '2': 'Quản lý',
    '3': 'Tổng quản lý',
    '4': 'Sale',
    '5': 'Telesale',
    '6': 'Kỹ thuật viên',
    '7': 'Kế toán',
    '8': 'Lễ tân',
    '9': 'Y tá',
    '10': 'Phòng răng',
    '11': 'Marketing',
    '12': 'Phòng tóc',
    '13': 'Bác sĩ'
}

export const LEAD_STATUS = {
    '0': 'Chưa có tiền trình',
    '1': 'Telesale chăm sóc',
    '2': 'Khách hàng quan tâm',
    '3': 'Đã đặt lịch tư vấn',
    '4': 'Khách mua dịch vụ'
}

export const LEAD_ORDER_STATUS = {
    '-1': 'Đã huỷ',
    '0': 'Đơn chưa thanh toán đầy đủ',
    '1': 'Đặt thành công',
    '2': 'Đã xác nhận'
}

export const ORDER_STATUS = {
    '-1': 'Đã huỷ',
    '1': 'Đặt thành công',
    '2': 'Đang tiến hàng',
    '3': 'Đã hoàn thành'
}

export const PAYMENT_METHOD = {
    '1': 'Ví Anhdior Lotus',
    '2': 'Tiền mặt',
    '5': 'Chuyển khoản',
    '6': 'Quẹt thẻ',
}

export const BOOKING_STATUS = {
    '-1': 'Đã huỷ',
    '1': 'Đặt thành công',
    '2': 'Xác nhận lịch',
    '3': 'AnhDior đón tiếp tại quầy',
    '4': 'Xác nhận kỹ thuật viên',
    '5': 'Bắt đầu thực hiện',
    '6': 'Thực hiện xong liệu trình',
    '7': 'Hoàn tất'
}

export const convertMoney = (money, showCurrency = false) => {
    if (!money) return money
    return (Math.round(money * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (showCurrency ? '₫' : '')
}

export const isManager = (auth) => {
    if (auth && (auth.account_type === 2 || auth.account_type === 3 || auth.account_type === 7 || auth.account_type === 8))
        return true
    return false
}

export function haveCommonObjects(array1, array2) {
    try {
        return (array1 ?? []).some(obj1 => (array2 ?? []).some(obj2 => obj1 === obj2));
    } catch (error) {
        return false
    }
}

export const hasPermission = (auth, permissions = []) => {
    if (auth && auth.permissions && (haveCommonObjects(auth.permissions ?? [], permissions)))
        return true
    return false
}

export const ConfirmationToast = ({ message, onConfirm, onCancel }) => {
    const handleConfirm = () => {
        onConfirm();
        toast.dismiss();
    };

    const handleCancel = () => {
        onCancel();
        toast.dismiss();
    };

    return (
        <Stack sx={{ gap: '8px', padding: '16px' }}>
            <Typography sx={{ color: 'black' }}>{message}</Typography>

            <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Button onClick={handleCancel}>Huỷ</Button>
                <Button variant='contained' onClick={handleConfirm}>Đồng ý</Button>
            </Stack>
        </Stack>
    );
};

export const showConfirmationToast = (message, onConfirm, onCancel) => {
    toast(<ConfirmationToast message={message} onConfirm={onConfirm} onCancel={onCancel} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: false,
    });
};