import React, { useState } from 'react'
import { Stack, Autocomplete, Button, Modal, TextField } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, viVN } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddCommunicationModal = ({ time, open, handleAdd, handleClose, note = '' }) => {
    const [_time, setTime] = useState(time)
    const [_note, setNote] = useState(note)

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (handleAdd && _time && _note) {
            handleAdd({ time: dayjs(_time).format(), note: _note })
            setNote(undefined)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thêm thông tin nhắn tin/gọi điện khách hàng</h4>
                <TextField
                    label="Nội dung liên lạc"
                    fullWidth
                    multiline
                    rows={3}
                    variant="standard" placeholder='Vd (gọi điện, nhắn tin với nội dung "aaa")' value={_note}
                    onChange={(e) => {
                        setNote(e.target.value)
                    }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={viVN.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale='vi'>
                    <DemoContainer
                        components={[
                            'StaticDateTimePicker',
                            'MobileDateTimePicker',
                        ]}
                    >
                        <DemoItem
                            label='Thời gian liên hệ'>
                            <MobileDateTimePicker value={_time} onChange={(value) => setTime(value)} format='HH:mm DD/MM/YYYY' label='Thời gian liên hệ' />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!_time || !_note} onClick={onAdd} color='success' style={{ flex: 1 }}>Thêm tin nhắn</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddCommunicationModal