import React, { useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Input } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddContactModal = ({ open, handleAdd, handleClose, source }) => {
    const [customer, setCustomer] = useState({
        username: '',
        full_name: '',
        email: '',
        extra_info: '',
        address: ''
    })
    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if(handleAdd) {
            handleAdd(customer)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thêm tài khoản</h4>

                <TextField
                    label="Số điện thoại"
                    variant="standard" placeholder='Số điện thoại' value={customer.username ?? ''}
                    onChange={(e) => setCustomer((old) => ({ ...old, username: e.target.value }))}
                />

                <TextField
                    label="Tên khách hàng"
                    variant="standard" placeholder='Tên khách hàng' value={customer.full_name ?? ''}
                    onChange={(e) => setCustomer((old) => ({ ...old, full_name: e.target.value }))}
                />

                <TextField
                    label="Email"
                    variant="standard" placeholder='Email' value={customer.email ?? ''}
                    onChange={(e) => setCustomer((old) => ({ ...old, email: e.target.value }))}
                />

                <TextField
                    label="Địa chỉ"
                    variant="standard" placeholder='Địa chỉ' value={customer.address ?? ''}
                    onChange={(e) => setCustomer((old) => ({ ...old, address: e.target.value }))}
                />

                <TextField
                    id="outlined-textarea"
                    variant="standard"
                    label="Ghi chú"
                    multiline
                    rows={4}
                    value={customer.extra_info ?? ''}
                    onChange={(e) => setCustomer((old) => ({ ...old, extra_info: e.target.value }))}
                />

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={customer.username.length !== 10 || customer.full_name.length === 0} onClick={onAdd} color='success' style={{ flex: 1 }}>Thêm tài khoản</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddContactModal