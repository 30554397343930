import { Autocomplete, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table } from '@mui/material'
import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DealItem from './components/DealItem';
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const Screen = () => {

    const onAddOrder = () => {

    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách deal' subTitle='Tìm thấy: 1000 deal' addButtonTitle='Thêm deal' onAddPress={onAddOrder} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box style={{ color: 'blue', display: 'flex', height: '36px', alignItems: 'center', justifyContent: 'center', width: '100px', marginRight: '16px', border: '1px solid #0000ff', borderRadius: '4px' }}>
                        <FilterAlt fontSize='13px' color='blue' />
                        <span style={{ color: 'blue', fontSize: '12px' }}>Bộ lọc</span>
                    </Box>
                    <TextField size='small' style={{ height: '32px', width: '320px' }} placeholder="Tên khách hàng, Email, số điện thoại" />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={['Thu Hà', 'Hải Yến']}
                        sx={{ width: '180px', marginLeft: '8px', marginRight: '8px' }}
                        renderInput={(params) => <TextField style={{ height: '36px', fontSize: '12px', color: 'black' }} size='small' {...params} label="Nhân sự phụ trách" />}
                    />
                </Box>
            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <TableContainer component={Paper} style={{marginLeft: '24px', marginRight: '24px'}}>
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead >
                            <TableRow>
                                <StyledTableCell style={{minWidth: '100px'}}>Ngày tạo</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '140px'}}>Tên deal</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '140px'}}>Chi phí dự kiến</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '150px'}}>Điểm đánh giá (50-100)</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '150px'}}>Khách hàng</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '150px'}}>Sản phẩm</StyledTableCell>
                                <StyledTableCell align="right" style={{minWidth: '150px'}}>Nhân viên</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
                                <DealItem row={row}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default Screen