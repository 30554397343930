import { Stack, Box, FormControl, InputLabel, MenuItem, Input, Select, Table } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt, } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import AccountItem from './components/AccountItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { hasPermission, showConfirmationToast, ACCOUNT_TYPE } from '../../../utils/utils';
import { toast } from 'react-toastify'
import AddAccountModal from './modals/AddAccountModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const Screen = () => {
    const [token] = useAtom(tokenAtom)
    const [staffs, setStaffs] = useState([])
    const [keyword, setKeyword] = useState('')
    const [accountType, setAccountType] = useState(0)
    const [auth] = useAtom(authAtom)
    const [showUpdateStaff, setShowUpdateStaff] = useState(null)

    const getStaffList = useCallback(() => {
        if (token) {
            axios.post('/accounts/customers/staff_crm_list/', {}, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data) {
                        setStaffs(response.data)
                    } else {
                        setStaffs([])
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setStaffs([])
                })
        }
    }, [token])

    const deleteStaff = (staff, index) => {
        showConfirmationToast(`Chắc chắn xoá tài khoản ${staff.full_name} <${staff.username}> khỏi danh sách nhân viên? Tài khoản này sẽ không thể truy cập CRM và app Anhdior!`,
            () => {
                axios.post('/accounts/users/revoke_staff/', { staff_id: staff.id }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                    .then((response) => {
                        if (response) {
                            toast.success('Xoá nhân viên thành công')
                            const newStaffs = [...staffs]
                            newStaffs.splice(index, 1)
                            setStaffs(newStaffs)
                        } else {
                            toast.error('Xoá nhân viên thất bại')
                        }
                    })
                    .catch((error) => {
                        console.log({ error })
                        toast.error('Xoá nhân viên thất bại')
                    })
            },
            () => { }
        )
    }

    const updateAccountType = (staff, index, accountType) => {
        showConfirmationToast(`Chắc chắn cập nhật lại chức danh ${staff.full_name} <${staff.username}> thành ${ACCOUNT_TYPE[accountType]}?`,
            () => {
                axios.post('/accounts/users/promote_staff/', { staff_id: staff.id, account_type: accountType }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                    .then((response) => {
                        if (response) {
                            toast.success('Cập nhật nhân viên thành công')
                            const newStaffs = [...staffs]
                            newStaffs[index] = { ...staffs[index], account_type: accountType }
                            setStaffs(newStaffs)
                        } else {
                            toast.error('Cập nhật nhân viên thất bại')
                        }
                    })
                    .catch((error) => {
                        console.log({ error })
                        toast.error('Cập nhật nhân viên thất bại')
                    })
            },
            () => { }
        )
    }

    useEffect(() => {
        getStaffList()
    }, [getStaffList])

    const updateStaff = (staff) => {
        setShowUpdateStaff(null)
        axios.post('/accounts/users/update_staff/', { username: staff.username, full_name: staff.full_name, account_type: staff.account_type }, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then((response) => {
                if (response) {
                    toast.success('Cập nhật nhân viên thành công')
                    getStaffList()
                } else {
                    toast.error('Cập nhật nhân viên thất bại')
                }
            })
            .catch((error) => {
                console.log({ error })
                toast.error('Cập nhật nhân viên thất bại')
            })
    }

    let filterStaffs = keyword.length > 0 ? staffs.filter(item => item.username.indexOf(keyword) >= 0 || item.full_name.indexOf(keyword) >= 0) : staffs
    filterStaffs = accountType > 0 ? filterStaffs.filter(item => item.account_type === accountType) : filterStaffs

    if (!(auth && hasPermission(auth, ['management_access']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách nhân viên' subTitle={`Tìm thấy: ${staffs.length} tài khoản`} addButtonTitle='Thêm nhân viên' onAddPress={() => setShowUpdateStaff({})} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>
                <Box sx={{ display: 'flex' }}>
                    <FormControl variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Họ tên, số điện thoại</InputLabel>
                        <Input
                            value={keyword} onChange={(e) => setKeyword(e.target.value)}
                            size='small' style={{ width: '300px' }}
                            placeholder="Tên khách hàng, số điện thoại"
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <FormControl style={{ width: '180px' }}>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '12px', color: 'black' }}>Lọc theo vị trí</InputLabel>
                        <Select
                            label="Tiến trình lead sales"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                        >
                            <MenuItem value={0}>Tất cả</MenuItem>
                            <MenuItem value={2}>Quản lý</MenuItem>
                            <MenuItem value={3}>Tổng quản lý</MenuItem>
                            <MenuItem value={4}>Sale</MenuItem>
                            <MenuItem value={5}>Telesale</MenuItem>
                            <MenuItem value={6}>Kỹ thuật viên</MenuItem>
                            <MenuItem value={7}>Kế toán</MenuItem>
                            <MenuItem value={8}>Lễ tân</MenuItem>
                            <MenuItem value={9}>Y tá</MenuItem>
                            <MenuItem value={10}>Phòng răng</MenuItem>
                            <MenuItem value={11}>Makerting</MenuItem>
                            <MenuItem value={12}>Phòng tóc</MenuItem>
                            <MenuItem value={13}>Bác sĩ</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <TableContainer component={Paper} style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tên nhân viên</StyledTableCell>
                                <StyledTableCell align="right">Số điện thoại</StyledTableCell>
                                <StyledTableCell align="right">Vị trí</StyledTableCell>
                                <StyledTableCell align="right">Truy cập CRM</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterStaffs.map((staff, index) => (
                                <AccountItem
                                    staff={staff}
                                    onDelete={() => deleteStaff(staff, index)}
                                    changeAccountType={(type) => updateAccountType(staff, index, type)}
                                    onEdit={() => setShowUpdateStaff(staff)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showUpdateStaff !== null &&
                    <AddAccountModal
                        handleClose={() => setShowUpdateStaff(null)}
                        open={showUpdateStaff !== null}
                        staff={showUpdateStaff}
                        handleUpdate={updateStaff}
                    />
                }
            </div>
        </div>
    )
}

export default Screen