import { Stack, Box, FormControl, InputLabel, MenuItem, Input, Select, Table } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt, } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import PermissionItem from './components/PermissionItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { hasPermission } from '../../../utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const permissions = {
    'crm_access': 'Truy cập CRM',
    'management_access': 'Quản trị tài khoản',
    'view_report': 'Báo cáo cá nhân',
    'view_report_all': 'Tất cả báo cáo',
    'export_report_all': 'Xuất báo cáo chung',
    'create_new_lead': 'Tạo Lead mới',
    'create_exist_lead': 'Tạo lead khách cũ',
    'view_lead': 'Xem Lead cá nhân',
    'view_lead_all': 'Xem tất cả Lead',
    'write_lead_all': 'Sửa tất cả Lead',
    'assign_lead': 'Có thể gắn Lead sale',
    'view_group': 'Xem nhóm',
    'view_group_all': 'Xem tất cả nhóm',
    'view_customer': 'Xem khách hàng',
    'view_customer_all': 'Xem tất cả khách hàng',
    'export_report_customer': 'Xuất báo cáo khách hàng',
    'view_order': 'Xem đơn hàng',
    'view_order_all': 'Xem tất cả đơn hàng',
    'export_report_order': 'Xuất báo cáo đơn hàng',
    'view_payment': 'Xem thanh toán',
    'view_payment_all': 'Xem tất cả thanh toán',
    'write_payment': 'Xác nhận thanh toán',
    'export_report_payment': 'Xuất báo cáo thanh toán',
    'view_comission': 'Xem hoa hồng',
    'view_comission_all': 'Xem tất cả hoa hồng',
    'write_comission': 'Phê duyệt hoa hồng',
    'export_report_comission': 'Xuất báo cáo hoa hồng',
}

const Screen = () => {
    const [token] = useAtom(tokenAtom)
    const [staffs, setStaffs] = useState([])
    const [keyword, setKeyword] = useState('')
    const [accountType, setAccountType] = useState(0)
    const [permission, setPermission] = useState(null)
    const [auth] = useAtom(authAtom)

    const getStaffList = useCallback(() => {
        if (token) {
            axios.post('/accounts/customers/staff_crm_list/', {}, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data) {
                        setStaffs(response.data)
                    } else {
                        setStaffs([])
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setStaffs([])
                })
        }
    }, [token])

    useEffect(() => {
        getStaffList()
    }, [getStaffList])

    const onAddLead = () => {

    }

    let filterStaffs = keyword.length > 0 ? staffs.filter(item => item.username.indexOf(keyword) >= 0 || item.full_name.indexOf(keyword) >= 0) : staffs
    filterStaffs = accountType > 0 ? filterStaffs.filter(item => item.account_type === accountType) : filterStaffs
    if (permission !== null) {
        filterStaffs = filterStaffs.filter((item) => item.permissions.indexOf(permission) >= 0)
    }

    if (!(auth && hasPermission(auth, ['management_access']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách nhân viên' subTitle={`Tìm thấy: ${staffs.length} tài khoản`} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>
                <Box sx={{ display: 'flex' }}>
                    <FormControl variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Họ tên, số điện thoại</InputLabel>
                        <Input
                            value={keyword} onChange={(e) => setKeyword(e.target.value)}
                            size='small' style={{ width: '300px' }}
                            placeholder="Tên khách hàng, số điện thoại"
                        />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px' }}>
                    <FormControl style={{ width: '180px' }}>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '12px', color: 'black' }}>Lọc theo quyền</InputLabel>
                        <Select
                            label="Lọc theo quyền"
                            value={permission}
                            onChange={(e) => setPermission(e.target.value)}
                        >
                            <MenuItem value={null}>Tất cả</MenuItem>
                            {
                                Object.keys(permissions).map((key) => {
                                    return (
                                        <MenuItem value={key}>{permissions[key]}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl style={{ width: '180px' }}>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '12px', color: 'black' }}>Lọc theo vị trí</InputLabel>
                        <Select
                            label="Tiến trình lead sales"
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                        >
                            <MenuItem value={0}>Tất cả</MenuItem>
                            <MenuItem value={2}>Quản lý</MenuItem>
                            <MenuItem value={3}>Tổng quản lý</MenuItem>
                            <MenuItem value={4}>Sale</MenuItem>
                            <MenuItem value={5}>Telesale</MenuItem>
                            <MenuItem value={6}>Kỹ thuật viên</MenuItem>
                            <MenuItem value={7}>Kế toán</MenuItem>
                            <MenuItem value={8}>Lễ tân</MenuItem>
                            <MenuItem value={9}>Y tá</MenuItem>
                            <MenuItem value={10}>Phòng răng</MenuItem>
                            <MenuItem value={11}>Makerting</MenuItem>
                            <MenuItem value={12}>Phòng tóc</MenuItem>
                            <MenuItem value={13}>Bác sĩ</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <TableContainer component={Paper} style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tên nhân viên</StyledTableCell>
                                <StyledTableCell align="right">Số điện thoại</StyledTableCell>
                                <StyledTableCell align="right">Phân quyền</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterStaffs.map((staff) => (
                                <PermissionItem staff={staff} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default Screen