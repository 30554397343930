import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const GroupItem = ({ source, onEdit }) => {
  const history = useHistory()

  const openDetail = () => {
    history.push(`/crm/group/${source.id}`)
  }

  return (
    <TableRow
      key={source.id.toString()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell style={{ cursor: 'pointer' }} component="th" scope="row" onClick={openDetail} sx={{ color: 'blue', textDecorationLine: 'underline' }}>
        {`${source.name}`}
      </TableCell>
      <TableCell align="right">{source.user_count}</TableCell>
      <TableCell align="right">{source.description}</TableCell>
      <TableCell align="right">{source.created_by ? source.created_by.full_name : ''}</TableCell>
      <TableCell align="right" >
        <Stack direction='row' sx={{ gap: '8px' }} justifyContent='flex-end'>
          <Button sx={{ fontSize: '12px' }} variant='contained' onClick={openDetail} color='secondary'>Danh sách KH</Button>
          <Button sx={{ fontSize: '12px' }} variant='contained' onClick={() => onEdit && onEdit()}>Thay đổi</Button>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default GroupItem