import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { ORDER_STATUS, convertMoney } from '../../../../utils/utils';
import { Card, Stack } from '@mui/material';
import dayjs from 'dayjs';

const WalletItem = ({ wallet }) => {
  const history = useHistory()
  const openDetail = () => {

  }

  return (
    <Card onClick={openDetail} sx={{ padding: '8px', cursor: 'pointer', marginBottom: '8px', minHeight: '100px' }}>
      <Stack >
        <span style={{ fontSize: 11, marginTop: 8, fontWeight: '600' }}>{`#${wallet.transaction_id}`}</span>
        <span style={{ fontSize: 11, marginTop: 8 }}>{`Số tiền: `}<span style={{color: wallet.transaction_type === 1 ? 'green' : 'red'}}>{`${wallet.transaction_type === 1 ? '+' : '-'}${convertMoney(wallet.amount, true)}`}</span></span>
        <span style={{ fontSize: 11, marginTop: 8 }}>{`Thời gian: ${dayjs(wallet.date).format('HH:mm DD/MM/YYYY')}`}</span>
        <span style={{ fontSize: 11, marginTop: 8 }}>{`Ghi chú: ${wallet.note}`}</span>
      </Stack>
    </Card>
  )
}

export default WalletItem