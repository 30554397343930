import React, { useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Input } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AddGroupModal = ({ open, handleAdd, handleClose, source }) => {
    const [_name, setName] = useState(source ? source.name ?? '' : '')
    const [_description, setDescription] = useState(source ? source.description ?? '' : '')

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (handleAdd && _name && _name.length > 0) {
            handleAdd({ name: _name, description: _description, id: source ? source.id : undefined })
            setName('')
            setDescription('')
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thêm nhóm khách hàng</h4>

                <TextField
                    label="Tên nhóm"
                    fullWidth
                    placeholder='Tên nhóm' value={_name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                />

                <TextField
                    label="Mô tả"
                    fullWidth
                    multiline
                    rows={3}
                    placeholder='Mô tả' value={_description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                />

                <Stack flexDirection='row' style={{marginTop: '48px'}} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{flex: 1}}>Huỷ</Button>
                    <Button disabled={_name.length === 0} onClick={onAdd} color='success' style={{flex: 1}}>Thêm nhóm</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddGroupModal