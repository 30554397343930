import React, { useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Typography, FormControl, Input } from '@mui/material'
import { convertMoney } from '../../../../utils/utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddComissionModal = ({ open, onChangeComission, handleClose, payment }) => {
    const [_sale1, setSale1] = useState(payment ? payment.comission_sale1 ?? 0 : 0)
    const [_sale2, setSale2] = useState(payment ? payment.comission_sale2 ?? 0 : 0)
    const [_telesale, setTelesale] = useState(payment ? payment.comission_telesale ?? 0 : 0)

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (onChangeComission && _sale1) {
            onChangeComission({ payment_id: payment.id, sale1: _sale1, sale2: _sale2, telesale: _telesale })
        }
    }

    const serviceName = payment?.lead_order?.orders.map((item) => item.service_name ?? item.service.name).join(', ') ?? ''

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Hoa hồng</h4>
                <Stack >
                    <Typography sx={{ fontSize: '13px' }}>{`Đơn hàng: ${serviceName}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Số tiền thanh toán: ${convertMoney(payment.amount, true)}`}</Typography>
                </Stack>

                {
                    payment?.lead_order?.sale1 &&
                    <FormControl>
                        <InputLabel>{`Sale chính - ${payment?.lead_order?.sale1.full_name}  - ${convertMoney(_sale1, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_sale1}
                            type='number'
                            onChange={(e) => {
                                setSale1(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                {
                    payment?.lead_order?.sale2 &&
                    <FormControl>
                        <InputLabel>{`Sale phụ - ${payment?.lead_order?.sale2.full_name} - ${convertMoney(_sale2, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_sale2}
                            type='number'
                            onChange={(e) => {
                                setSale2(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                {
                    payment?.lead_order?.telesale &&
                    <FormControl>
                        <InputLabel>{`Telesale - ${payment?.lead_order?.telesale.full_name}  - ${convertMoney(_telesale, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_telesale}
                            type='number'
                            onChange={(e) => {
                                setTelesale(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!_sale1} onClick={onAdd} color='success' style={{ flex: 1 }}>Thay đổi hoa hồng</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddComissionModal