import React, { useState } from 'react'
import { Stack, Autocomplete, Button, Modal, TextField, Select, InputLabel, MenuItem } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { vi } from 'date-fns/locale';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const DateRangeModal = ({ startDate, endDate, onConfirm, handleClose, open }) => {
    const [state, setState] = useState([
        {
            startDate: (startDate ? dayjs(startDate) : dayjs()).toDate(),
            endDate: (endDate ? dayjs(endDate) : dayjs()).toDate(),
            key: 'selection'
        }
    ]);

    const confirmAction = () => {
        if(onConfirm) {
            onConfirm({startDate: state[0].startDate, endDate: state[0].endDate})
        }
    }

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thông tin thanh toán</h4>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    months={2}
                    direction="horizontal"
                    locale={vi}
                />

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button onClick={confirmAction} color='success' style={{ flex: 1 }}>Xác nhận</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default DateRangeModal