import React, { useState } from 'react'
import { Stack, Autocomplete, Button, Modal, TextField, Select, InputLabel, MenuItem } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import { MobileDateTimePicker, viVN } from '@mui/x-date-pickers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const methods = [
    { name: 'Ví Anhdior', id: 1 },
    { name: 'Tiền mặt', id: 2 },
    { name: 'Chuyển khoản', id: 5 },
    { name: 'Quẹt thẻ', id: 6 },
]

const types = [
    { name: 'Thanh toán toàn bộ', id: 1 },
    { name: 'Đặt cọc', id: 2 },
    { name: 'Thanh toàn còn lại', id: 3 },
    { name: 'Thanh toán thêm một phần', id: 4 },
    { name: 'Hoàn tiền', id: 5 },
]

const ConfirmPaymentModal = ({ payment, onAddPayment, handleClose, open }) => {
    const [time, setTime] = useState(dayjs(payment.payment_date))
    const [note, setNote] = useState(payment.note)
    const [amount, setAmount] = useState(payment.amount)
    const [type, setType] = useState(payment.payment_type)
    const [method, setMethod] = useState(payment.payment_method)

    const onAdd = () => {
        onAddPayment({
            ...payment,
            lead_order: payment.lead_order.id, payment_date: dayjs(time).format(),
            amount: amount, note: note,
            payment_type: type, payment_method: method,
        })
    }

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thông tin thanh toán</h4>
                <Stack spacing={1}>
                    <InputLabel id="type-select-label">Loại thanh toán</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select-select"
                        value={type}
                        label="Loại thanh toán"
                        onChange={(e) => setType(e.target.value)}
                    >
                        {(types ?? []).map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Stack spacing={1}>
                    <InputLabel id="method-select-label">Hình thức thanh toán</InputLabel>
                    <Select
                        labelId="method-select-label"
                        id="method-select-select"
                        value={method}
                        label="Hình thức thanh toán"
                        onChange={(e) => setMethod(e.target.value)}
                    >
                        {(methods ?? []).map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <NumericFormat
                    customInput={TextField}
                    label="Số tiền thanh toán"
                    thousandSeparator='.'
                    decimalSeparator=','
                    type='text'
                    suffix='đ'
                    variant="standard" placeholder='Số tiền thanh toán' value={amount}
                    onValueChange={(values) => setAmount(parseFloat(values.value))}
                    style={{ flex: 1 }}
                />
                <TextField
                    label="Ghi chú"
                    fullWidth
                    multiline
                    rows={3}
                    variant="standard" placeholder='Ghi chú' value={note}
                    onChange={(e) => {
                        setNote(e.target.value)
                    }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={viVN.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale='vi'>
                    <DemoContainer
                        components={[
                            'StaticDateTimePicker',
                            'MobileDateTimePicker',
                        ]}
                    >
                        <DemoItem
                            label='Thời gian thanh toán'>
                            <MobileDateTimePicker value={time} onChange={(value) => setTime(value)} format='HH:mm DD/MM/YYYY' label='Thời gian thanh toán' />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!time || !type || !method} onClick={onAdd} color='success' style={{ flex: 1 }}>Xác nhận</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default ConfirmPaymentModal