import { Autocomplete, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table } from '@mui/material'
import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import Button from '@mui/material/Button';
import StaffAbilityItem from './components/StaffAbilityItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const permissions = [
    { title: 'Hifu' },
    { title: 'Bụi kim cương' },
    { title: 'Massage đá nóng' },
]

const Screen = () => {

    const onAddLead = () => {

    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Chi tiết KTV' onAddPress={onAddLead} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>

            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <div style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Tên dịch vụ</StyledTableCell>
                                    <StyledTableCell align="right">KTV Chính</StyledTableCell>
                                    <StyledTableCell align="right">KTV Phụ</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permissions.map((row) => (
                                    <StaffAbilityItem row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default Screen