import { Autocomplete, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table } from '@mui/material'
import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'
import SMSHistoryItem from './components/SMSHistoryItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const Screen = () => {

    const onAddLead = () => {

    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ActionsBar title='Danh sách tin nhắn SMS' subTitle='Tìm thấy: 1000 SMS' addButtonTitle='Thêm mới tin nhắn' onAddPress={onAddLead} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' }}>
                <Box sx={{ display: 'flex' }}>

                </Box>
                <Box sx={{ display: 'flex' }}>
                </Box>
            </Box>
            <div style={{ display: "flex", width: '100%', overflowY: 'scroll', marginTop: '24px' }}>
                <TableContainer component={Paper} style={{marginLeft: '24px', marginRight: '24px'}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Thời gian gửi</StyledTableCell>
                                <StyledTableCell align="right">Nội dung tin nhắn</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
                                <SMSHistoryItem row={row}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default Screen