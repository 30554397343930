import React from 'react'
import {Typography} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const permissions = {
    'crm_access': 'Truy cập CRM',
    'management_access': 'Quản trị tài khoản',
    'view_report': 'Báo cáo cá nhân',
    'view_report_all': 'Tất cả báo cáo',
    'export_report_all': 'Xuất báo cáo chung',
    'create_new_lead': 'Tạo Lead mới',
    'create_exist_lead': 'Tạo lead khách cũ',
    'view_lead': 'Xem Lead cá nhân',
    'view_lead_all': 'Xem tất cả Lead',
    'write_lead_all': 'Sửa tất cả Lead',
    'assign_lead': 'Có thể gắn Lead sale',
    'view_group': 'Xem nhóm',
    'view_group_all': 'Xem tất cả nhóm',
    'view_customer': 'Xem khách hàng',
    'view_customer_all': 'Xem tất cả khách hàng',
    'export_report_customer': 'Xuất báo cáo khách hàng',
    'view_order': 'Xem đơn hàng',
    'view_order_all': 'Xem tất cả đơn hàng',
    'export_report_order': 'Xuất báo cáo đơn hàng',
    'view_payment': 'Xem thanh toán',
    'view_payment_all': 'Xem tất cả thanh toán',
    'write_payment': 'Xác nhận thanh toán',
    'export_report_payment': 'Xuất báo cáo thanh toán',
    'view_comission': 'Xem hoa hồng',
    'view_comission_all': 'Xem tất cả hoa hồng',
    'write_comission': 'Phê duyệt hoa hồng',
    'export_report_comission': 'Xuất báo cáo hoa hồng',
}


const PermissionItem = ({ staff }) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/management/permission/${staff.username}`)
    }

    return (
        <StyledTableRow
            key={staff.username}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            
        >
            <TableCell component="th" scope="row">
                {staff.username}
            </TableCell>
            <TableCell align="right">{staff.full_name}</TableCell>
            <TableCell align="right">
            <Typography sx={{fontSize: '12px'}}>
                {staff.permissions.map((item) => (permissions[item] ?? '').length > 0 ? `${permissions[item] ?? ''}, ` : '')}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                    <Button onClick={openDetail} color="success">Thay đổi</Button>
                </div>
            </TableCell>
        </StyledTableRow>
    )
}

export default PermissionItem