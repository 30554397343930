import { Autocomplete, Box, FormControl, Icon, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import ContactLeadItem from './components/ContactLeadItem';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, leadsAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { hasPermission } from '../../../utils/utils';
import { MultiSelect } from "react-multi-select-component";
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});


const Screen = () => {
    const [leads, setLeads] = useAtom(leadsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()
    const [statusState] = useState([
        { title: 'Chưa có tiến trình', description: 'Chưa có tiến trình', id: 0 },
        { title: 'Telesale', description: 'Telesale đang liên hệ khách hàng', id: 1 },
        { title: 'Khách quan tâm', description: 'Telesale đã liên hệ khách hàng, khách quan tâm tới dịch vụ và đồng ý đến nghe tư vấn', id: 2 },
        { title: 'Đã đặt lịch tư vấn', description: 'Đã đặt lịch tư vấn trực tiếp tại cơ sở', id: 3 },
        // { title: 'Khách mua liệu trình', description: 'Khách đã đồng ý mualiệu trình', id: 4 },
    ]);
    const [keyword, setKeyword] = useState('')
    const [staffs] = useAtom(staffsAtom)
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const authUser = useAtomValue(authAtom)

    useEffect(() => {
        const getLeads = async () => {
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Token ${token}`
                
                axios.post('/accounts/leads/get_leads/', {
                    staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null
                }, { headers: { Authorization: `Token ${token}` } })
                    .then((data) => {
                        console.log({ data })
                        if (data && data.data) {
                            setLeads(data.data)
                        }
                    })
                    .catch((error) => {
                        console.log({ error })
                    })
            }
        }

        getLeads()
    }, [setLeads, token, selectedStaffs])

    const onAddLead = () => {
        history.push(`/crm/lead/new`)
    }

    const filterLeads = keyword.length > 0 ? (leads ?? []).filter((item) => item.user && (item.user.username.indexOf(keyword) >= 0 || item.user.full_name.indexOf(keyword) >= 0)) : leads
    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))

    if(!(authUser && hasPermission(authUser, ['write_lead_all', 'create_new_lead', 'view_lead', 'view_lead_all']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: '#eee' }}>
            <Stack width='95%'>
                <ActionsBar title='Danh sách Lead' subTitle={`Tìm thấy ${filterLeads.length} lead phù hợp`} addButtonTitle='Thêm Lead mới' onAddPress={onAddLead} />
                <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
                    <FormControl variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Tên khách hàng, số điện thoại</InputLabel>
                        <Input
                            value={keyword} onChange={(e) => setKeyword(e.target.value)}
                            size='small' style={{ width: '300px' }}
                            placeholder="Tên khách hàng, số điện thoại"
                        />
                    </FormControl>
                    {
                        hasPermission(authUser, ['view_lead_all', 'write_lead']) && (
                            <Stack sx={{ width: '250px', zIndex: 20, fontSize: '12px' }}>
                                    <MultiSelect
                                        options={staffOptions}
                                        value={selectedStaffs}
                                        onChange={setSelectedStaffs}
                                        labelledBy="Chọn nhân viên"
                                        overrideStrings={{
                                            "allItemsAreSelected": "Tất cả",
                                            "clearSearch": "Xoá",
                                            "clearSelected": "Bỏ chọn",
                                            "search": "Tìm kiếm",
                                            "selectAll": "Chọn tất cả",
                                            "selectAllFiltered": "Chọn tất cả",
                                            "selectSomeItems": "Lựa chọn nhân viên"
                                        }}
                                    />
                                </Stack>
                        )
                    }
                </Stack>
                <div style={{ display: "flex", width: '100%', height: 'calc(100vh - 210px)', marginTop: '32px', gap: '16px' }}>
                    {statusState.map((state, index) => {
                        return (
                            <div key={state.id.toString()} style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: '15vw', height: '100%', background: '#eee', borderRadius: '4px', }}>

                                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                    <p style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '8px', fontSize: '15px', fontWeight: '700', color: 'black' }}>{state.title}</p>
                                    <Tooltip title={state.description} >
                                        <InfoIcon fontSize='small' color='info' />
                                    </Tooltip>
                                </div>
                                <div
                                    style={{ borderRadius: 4, marginLeft: 8, marginRight: 8, overflowY: 'scroll', flex: 1, scrollbarWidth: 'thin', scrollbarColor: '#888 #eee' }}
                                >
                                    {
                                        (filterLeads ?? []).filter((item) => item.status === state.id).map((item, index) => {
                                            return (
                                                <ContactLeadItem key={item.id.toString()} lead={item} />
                                            )
                                        })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Stack>
        </div>
    )
}

export default Screen