import React, { useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AddServiceModal = ({ services, open, handleAdd, handleClose, service = undefined, note = '' }) => {
    const [_service, setService] = useState(service)
    const [_note, setNote] = useState(note)
    const [_service_name, setServiceName] = useState('')

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (handleAdd && _service) {
            handleAdd({ service: _service, note: _note, service_name: _service_name })
            setService(undefined)
            setNote(undefined)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Thêm dịch vụ khách hàng quan tâm</h4>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={(services ?? [])}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Thêm dịch vụ" />}
                    onChange={(e, newValue) => {
                        if (newValue && newValue.id) {
                            setService(newValue)
                            setServiceName(newValue.name)
                        }
                    }}
                />

                <TextField
                    label="Tên dịch vụ hiện tại"
                    fullWidth
                    variant="standard" placeholder='Tên dịch vụ hiện tại' value={_service_name}
                    onChange={(e) => {
                        setServiceName(e.target.value)
                    }}
                />

                <TextField
                    label="Ghi chú"
                    fullWidth
                    multiline
                    rows={3}
                    variant="standard" placeholder='Ghi chú' value={_note}
                    onChange={(e) => {
                        setNote(e.target.value)
                    }}
                />

                <Stack flexDirection='row' style={{marginTop: '48px'}} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{flex: 1}}>Huỷ</Button>
                    <Button disabled={!_service} onClick={onAdd} color='success' style={{flex: 1}}>Thêm dịch vụ</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddServiceModal