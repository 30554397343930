import React from 'react'
import { Box, Card, Divider, Stack } from '@mui/material'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { LEAD_STATUS } from '../../../../utils/utils'

const LeadItem = ({ lead, style, ...props }) => {
    const history = useHistory()
    const openDetail = () => {
        history.push(`/crm/lead/${lead.user.username}/${lead.id}`)
    }

    return (
        <Card onClick={openDetail} sx={{ padding: '8px', cursor: 'pointer', marginBottom: '8px' }}>
            <Stack >
                <span style={{ fontSize: 11, marginTop: 8 }}>{`Trạng thái: ${LEAD_STATUS[lead.status]}`}</span>
                <span style={{ fontSize: 11, marginTop: 8 }}>{'Thời gian tạo: '}
                    <span style={{ color: 'gray' }}>{dayjs(lead.created_time).format('HH:mm:ss DD/MM/YYYY')}</span>
                </span>
                <span style={{ fontSize: 11, marginTop: 8 }}>{'Cập nhật gần nhất: '}
                    <span style={{ color: 'gray' }}>{dayjs(lead.updated_time).format('HH:mm:ss DD/MM/YYYY')}</span>
                </span>
                <span style={{ fontSize: 11, marginTop: 8 }}>{`Cửa hàng: ${lead.shop.name}`}</span>
                {
                    lead.sale1 &&
                    <span style={{ fontSize: 11, marginTop: 8 }}>{'Sale chính: '}
                        <span>{lead.sale1.full_name}</span>
                    </span>
                }
                {
                    lead.telesale &&
                    <span style={{ fontSize: 11, marginTop: 8 }}>{'Telesale: '}
                        <span>{lead.telesale.full_name}</span>
                    </span>
                }
                {
                    lead.sale2 &&
                    <span style={{ fontSize: 11, marginTop: 8 }}>{'Sale phụ: '}
                        <span >{lead.sale2.full_name}</span>
                    </span>
                }
            </Stack>
        </Card>
    )
}

export default LeadItem