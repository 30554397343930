import React, { useState } from 'react'
import { Stack, InputLabel, FormControl, Select, MenuItem, Button, Modal, TextField } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddAccountModal = ({ staff, open, handleUpdate, handleClose }) => {
    const [_name, setName] = useState(staff ? staff.full_name ?? '' : '')
    const [_phone, setPhone] = useState(staff ? staff.username ?? '' : '')
    const [_type, setType] = useState(staff ? staff.account_type : null)


    const onClose = () => {
        if (handleClose) {
            setName('')
            setPhone('')
            setType(null)
            handleClose()
        }
    }

    const onAdd = () => {
        if (handleUpdate) {
            handleUpdate({ full_name: _name, username: _phone, account_type: _type })
            setName('')
            setPhone('')
            setType(null)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Cập nhật thông tin nhân viên</h4>

                <TextField
                    label="Họ tên"
                    fullWidth
                    variant="standard" placeholder='Họ tên' value={_name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                />

                <TextField
                    label="Số điện thoại"
                    fullWidth
                    variant="standard" placeholder='Số điện thoại' value={_phone}
                    onChange={(e) => {
                        setPhone(e.target.value)
                    }}
                    disabled={staff && staff.username}
                />

                <FormControl>
                    <InputLabel id="demo-simple-select-label">Lựa chọn vị trí</InputLabel>
                    <Select
                        label="Lựa chọn vị trí<"
                        value={_type}
                        onChange={(e) => setType(e.target.value)}
                        variant='standard'
                        fullWidth
                    >
                        <MenuItem value={2}>Quản lý</MenuItem>
                            <MenuItem value={3}>Tổng quản lý</MenuItem>
                        <MenuItem value={4}>Sale</MenuItem>
                        <MenuItem value={5}>Telesale</MenuItem>
                        <MenuItem value={6}>Kỹ thuật viên</MenuItem>
                        <MenuItem value={7}>Kế toán</MenuItem>
                        <MenuItem value={8}>Lễ tân</MenuItem>
                        <MenuItem value={9}>Y tá</MenuItem>
                        <MenuItem value={10}>Phòng răng</MenuItem>
                        <MenuItem value={11}>Makerting</MenuItem>
                        <MenuItem value={12}>Phòng tóc</MenuItem>
                        <MenuItem value={13}>Bác sĩ</MenuItem>
                    </Select>
                </FormControl>

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!(_type !== null && _phone.length === 10 && _name.length > 5)} onClick={onAdd} color='success' style={{ flex: 1 }}>Cập nhật</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddAccountModal