import * as React from 'react';
import {Box, Button} from '@mui/material';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation, useHistory } from 'react-router-dom';
import { ReceiptLong, } from '@mui/icons-material'

const ActionsBar = ({ title, subTitle, addButtonTitle, onAddPress }) => {

    return (
        <Box sx={{ height: 80, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ color: 'white', display: 'flex', marginLeft: '16px', alignItems: 'center' }}>
                <ReceiptLong color='primary' />
                <Box sx={{ display: 'flex', flexDirection: 'column', color: 'black', marginLeft: '16px' }}>
                    <span style={{color: 'black', fontSize: '18px', fontWeight: 'bold'}}>{title}</span>
                    <span style={{color: 'black', fontSize: '12px', marginTop: 5}}>{subTitle}</span>
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex', color: 'white' }, marginRight: '16px' }}>
                {addButtonTitle &&
                    <Button sx={{fontSize: '16px'}} onClick={() => onAddPress && onAddPress()}>
                        {addButtonTitle}
                    </Button>
                }
            </Box>
        </Box>
    );
}

export default ActionsBar