import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CampaignItem = ({row}) => {
    const history = useHistory()

    return(
        <StyledTableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Giảm giá 30/04
              </TableCell>
              <TableCell align="right">{dayjs().format('HH:mm:ss DD/MM/YYYY')}</TableCell>
              <TableCell align="right">{dayjs().format('HH:mm:ss DD/MM/YYYY')}</TableCell>
              <TableCell align="right">{'Bình Phạm'}</TableCell>
              <TableCell align="right">{'facebook'}</TableCell>
              <TableCell align="right">{'Thông tin chi tiết của chiến dịch'}</TableCell>
            </StyledTableRow>
    )
}

export default CampaignItem