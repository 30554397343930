// src/LoginPage.js
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { authAtom, tokenAtom } from '../../../actions';
import { API_BASE_URL } from '../../../actions/api'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { hasPermission } from '../../../utils/utils';

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
};

const formStyle = {
    backgroundColor: '#f2f2f2',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '400px',
    width: '90%',
    textAlign: 'center',
};

const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
};

const buttonStyle = {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

function LoginPage() {
    const history = useHistory()
    const location = useLocation()
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [token, setToken] = useAtom(tokenAtom)
    const [, setAuth] = useAtom(authAtom)

    useState(() => {
        if(token && location && location.state && location.state.from)
            history.push(location.state.from)
    }, [token, location])

    const handleLogin = async () => {
        try {
            const response = await axios.post('/rest-auth/login/', {
                username: username,
                password: password
            })
            console.log(response)
            if (response && response.data && response.data.key) {
                
                axios.defaults.headers.common['Authorization'] = `Token ${response.data.key}`
    
                const userRes = await axios.get('/rest-auth/user/')
                if(userRes && userRes.data) {
                    if(!(userRes.data.account_type !== 1 && hasPermission(userRes.data, ['crm_access']))) {
                        toast.error('Tài khoản không được cấp quyền CRM')
                        return
                    }
                    setAuth(userRes.data)
                    setToken(response.data.key)
                    history.push('/crm/report')
                } else {
                    toast.error('Tài khoản không hợp lệ')
                }
            } else {
                toast.error('Đăng nhập không thành công')
            }
        } catch (error) {
            toast.error('Đăng nhập không thành công')
        }
    };

    return (
        <div style={containerStyle}>
            <div style={formStyle}>
                <h1>Đăng nhập - Anhdior CRM</h1>
                <input
                    type='tel'
                    placeholder="Số điện thoại"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={inputStyle}
                />
                <input
                    type="password"
                    placeholder="Mật khẩu"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={inputStyle}
                />
                <button onClick={handleLogin} style={buttonStyle}>
                    Đăng nhập
                </button>
            </div>
        </div>
    );
}

export default LoginPage;
